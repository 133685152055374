import './viewer.css'

import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import { selectUrlCaseId, selectUrlSlideId } from 'pages/viewer'
import { ISlideState, useOpenViewers, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { memo, RefObject, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { DOT_WITH_SPACE } from 'shared/text-constants'
import { Center, IconElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { useViewer } from 'viewer/container/lib/useViewer'
import { ViewerHelpContainer, ViewerWarningContainer } from 'viewer/help'
import MapContainer from 'viewer/map/MapContainer'
import { ToolsModalContainer } from 'viewer/tools'

import VIEWER_COLORS from './VIEWER_COLORS'
import ViewerFloatingActions from './ViewerFloatingActions'

const StyledViewer = styled.div<{ isActive: boolean; color: string }>`
  position: relative;
  background-color: #ffffff;
  height: 100%;
  outline: ${({ color, isActive }) => isActive && '3px solid ' + color};
`

export const WrongSlideId = styled(Center)`
  background-color: var(--color-bg-2);
`
const StyledIconContainer = styled.div`
  position: absolute;
  left: 48%;
  top: 48%;
  z-index: 99;
`

type Props = {
  viewerId: TViewerId
  slideState: ISlideState
  map: IMapOl
  active: boolean
  viewerGridRef: RefObject<HTMLDivElement>
}

const Viewer = memo(({ active, map, slideState, viewerGridRef, viewerId }: Props) => {
  const queryClient = useQueryClient()
  const { caseId, slideId, source } = slideState
  const urlCaseId = useSelector(selectUrlCaseId) || caseId
  const urlSlideId = useSelector(selectUrlSlideId)
  const { activeViewerId, isFastTravel, setActiveViewerId, viewerType } = useViewerPageProvided()
  const currentSlideId = viewerType === 'DEFAULT' ? urlSlideId || 0 : slideId
  const { data: slide } = useSlideQuery({
    caseId: viewerType === 'DEFAULT' ? urlCaseId : currentSlideId,
    slideId: currentSlideId,
    source,
  })
  const { t } = useTranslation()
  const cacheMessageShown = useTypedSelector((state) => state.viewerPage.isCacheMessage)
  const { isAvailable, isLoading } = useViewer(viewerId)
  const { openViewerIds } = useOpenViewers()
  const currentBlock = useRef<HTMLDivElement>(null)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, urlCaseId])
  const { isLoadingCaseGrouped, slidesIds } = useSlideGrouped(urlCaseId)
  const hasSlides = !!slidesIds.length

  useEffect(() => {
    if (caseRecord?.name) {
      navigator.clipboard.writeText(caseRecord?.name)
    }
  }, [])

  if (isLoading || isLoadingCaseGrouped) {
    return <StyledViewer isActive={active} color={VIEWER_COLORS[viewerId]} />
  }

  return (
    <StyledViewer
      onClick={() => {
        if (isFastTravel || viewerId === activeViewerId) return
        setActiveViewerId(viewerId)
      }}
      isActive={openViewerIds.length === 1 ? false : active}
      color={VIEWER_COLORS[viewerId]}
      ref={currentBlock}
      id={`MAP_VIEW${viewerId}`}
    >
      {isAvailable && !!slide ? (
        <>
          {isFastTravel && activeViewerId === viewerId && (
            <StyledIconContainer
              style={{ left: '50%', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 99 }}
            >
              <IconElement size={'xl'} name={'cameraSight'} />
            </StyledIconContainer>
          )}
          {cacheMessageShown && activeViewerId === viewerId && <ViewerWarningContainer status={caseRecord?.status} />}
          <MapContainer viewerGridRef={viewerGridRef} viewerId={viewerId} slide={slide} map={map} />
          {activeViewerId === viewerId && <ViewerHelpContainer viewerId={viewerId} />}
          <ToolsModalContainer viewerId={viewerId} />
        </>
      ) : (
        <WrongSlideId>
          <TitleElement>
            {hasSlides ? t('Слайд не найден') + DOT_WITH_SPACE + t('Выберите другой') : t('Слайды ещё не загружены')}
            {DOT_WITH_SPACE}
          </TitleElement>
        </WrongSlideId>
      )}
      <ViewerFloatingActions viewerId={viewerId} />
    </StyledViewer>
  )
})

export default Viewer
