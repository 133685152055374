import { FilterType, IActiveFilter, ISubMenu, t } from 'shared/ui/table/lib/common'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

export enum EUploadedFileFilterType {
  UPLOADED_FILE_TYPES = 'uploadedFileTypes',
  UPLOADED_FILE_ATTACHMENT_STATES = 'uploadedFileAttachmentStates',
  CREATED_DATE = 'createdDate',
  ATTACHED_TO_CASE_DATE = 'attachedToCaseDate',
  DELETED_DATE = 'deletedDate',
}

export type TDateRange = {
  from: string | null
  to: string | null
}

export const updateDateRangeFilter = (
  prevFilters: IActiveFilter[],
  filter: ISubMenu,
  filterKey?: string,
  dateRange?: [string, string],
): IActiveFilter[] => {
  if (!dateRange) {
    return prevFilters.filter((f) => f.key !== filterKey)
  }

  const { filterType, key, title } = filter

  const filterIndex = prevFilters.findIndex((filter) => filter.key === filterKey)

  if (filterIndex !== -1) {
    const updatedFilters = [...prevFilters]
    updatedFilters[filterIndex].activeFilters = [
      {
        dateRange: { from: dateRange[0], to: dateRange[1] },
        filterName: updatedFilters[filterIndex].title,
        filterValue: true,
        key,
      },
    ]

    return updatedFilters
  }

  return [
    ...prevFilters,
    {
      activeFilters: [
        {
          dateRange: { from: dateRange[0], to: dateRange[1] },
          filterName: title,
          filterValue: true,
          key,
        },
      ],
      filterType,
      key,
      title,
    },
  ]
}
// Создание списка подфильтров

export const createUFSubMenu = <T extends string>(
  key: string,
  title: string,
  queryValues: T[],
  filterType: FilterType,
  labelCb: (it: T) => string,
): ISubMenu => ({
  filterType,
  items: queryValues?.map((queryValue, index) => ({
    key: `${queryValue}:${index}`,
    label: t(labelCb(queryValue)),
    queryValue,
  })),
  key,
  title: t(title),
})

const MINIMUM_INDEX_OFFSET = 1
const DEFAULT_FILES_COUNT_TO_REMOVE = 2

export const getNextOrPrevFile = (
  selectedFiles: IUploadedFileDTO[],
  selectedFile?: IUploadedFileDTO,
  files?: IListOfItemsCursorCases<IUploadedFileDTO>[],
) => {
  if (!files?.length) return

  const flattenedContent = files.flatMap((file) => file.content)
  const maxIndex = flattenedContent.length - (selectedFiles.length || DEFAULT_FILES_COUNT_TO_REMOVE)

  const getIndex = (file: IUploadedFileDTO | undefined) =>
    flattenedContent.findIndex((f) => f.uploadedFileId === file?.uploadedFileId)

  let index: number

  if (selectedFiles.length) {
    const indices = selectedFiles.map(getIndex)
    index = Math.max(...indices) + MINIMUM_INDEX_OFFSET
  } else {
    index = getIndex(selectedFile) + MINIMUM_INDEX_OFFSET
  }

  index = Math.min(index, maxIndex - MINIMUM_INDEX_OFFSET)
  return flattenedContent[index]
}

export const getNextOrPrevIndex = (
  selectedFilesLength: number,
  selectedFile?: IUploadedFileDTO,
  files?: IListOfItemsCursorCases<IUploadedFileDTO>[],
) => {
  if (!files?.length) return

  const flattenedContent = files.flatMap((file) => file.content)
  const index = flattenedContent.findIndex((file) => file.uploadedFileId === selectedFile?.uploadedFileId)
  const maxIndex = flattenedContent.length - (selectedFilesLength || DEFAULT_FILES_COUNT_TO_REMOVE)
  const newIndex = index - (selectedFilesLength || MINIMUM_INDEX_OFFSET)
  if (index >= maxIndex) {
    return maxIndex
  }
  return newIndex < 0 ? 0 : newIndex
}
