import 'antd/es/date-picker/style/index'

import { Dropdown, Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import icons from 'assets/icons/index'
import { parseISO } from 'date-fns'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { FilterBubble } from 'features/cases-management/ui/filters/FilterBubble'
import { EUploadedFileFilterType } from 'features/uploaded-file/lib/common'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDatePickerLocale } from 'shared/lib/date/getDatePickerLocale'
import i18next from 'shared/lib/i18n/i18n'
import { DateRangePicker } from 'shared/ui/DatePicker'
import { AvatarElement, IconElement } from 'shared/ui/kit'
import { IActiveFilter, ISubMenu, ISubMenuItem, ISubMenuTitleProps } from 'shared/ui/table/lib/common'
import { DropdownFilterButton } from 'shared/ui/table/ui/DropdownFilterButton'
import styled from 'styled-components'

export const t = i18next.t

type TProps = {
  handleDataRangeChange: (
    dates: [Date | null, Date | null] | null,
    dateStrings: [string, string],
    filterType?: EUploadedFileFilterType,
  ) => void
  menu: (null | JSX.Element)[]
  selectedFilters: IActiveFilter[]
  handleRemoveFilter: (subMenuKey: React.Key) => void
  dataMenuConfig: ISubMenu[]
  handleFilterChange: (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => void
  setSubMenuKeyFilter: (key: string) => void
}

export const TableFilter: FC<TProps> = ({
  dataMenuConfig,
  handleDataRangeChange,
  handleFilterChange,
  handleRemoveFilter,
  menu,
  selectedFilters,
  setSubMenuKeyFilter,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const { isFiltersPicked, setFilterHeight, setIsFiltersPicked } = useCaseManagementContext()
  const filterRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsFiltersPicked(selectedFilters.length > 0)
    setTimeout(() => setFilterHeight(filterRef.current ? filterRef.current.getBoundingClientRect().height : 0))
  }, [selectedFilters])

  const isFilterActive = (itemKey: string) =>
    selectedFilters.some((filter) => filter.activeFilters.some((activeFilter) => activeFilter.key === itemKey))

  const handleVisibleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev)
    setSubMenuKeyFilter('')
  }, [])

  useEffect(() => {
    setTimeout(() => setFilterHeight(filterRef.current ? filterRef.current.getBoundingClientRect().height : 0))
  }, [selectedFilters])

  return (
    <DropdownWrapper isFiltersPicked={isFiltersPicked} ref={filterRef}>
      <FilterDropdown
        isFiltersPicked={isFiltersPicked}
        menu={menu}
        isDropdownOpen={isDropdownOpen}
        handleVisibleDropdown={handleVisibleDropdown}
      />
      {isFiltersPicked &&
        selectedFilters.map((filter) => (
          <FilterBubble
            menuConfig={dataMenuConfig}
            key={filter.key}
            activeFilter={filter}
            handleRemoveFilter={handleRemoveFilter}
            handleFilterChange={handleFilterChange}
            isFilterActive={isFilterActive}
            handleDataRangeChange={handleDataRangeChange}
          />
        ))}
    </DropdownWrapper>
  )
}

export const SubMenuTitle: React.FC<ISubMenuTitleProps> = ({ title }) => (
  /** Отдельный комопнент для заголовка саб меню, чтоб изменить стандартные иконки Antd на кастомные, по дизайну */
  <div className="sub-menu-title">
    <span>{title}</span>
    <icons.sectionIsClose />
  </div>
)

const FilterDropdown: FC<{
  menu: (null | JSX.Element)[]
  isFiltersPicked: boolean
  isDropdownOpen: boolean
  handleVisibleDropdown: () => void
}> = ({ handleVisibleDropdown, isDropdownOpen, isFiltersPicked, menu }) => (
  <Dropdown
    overlay={<StyledDropdown triggerSubMenuAction="click">{menu}</StyledDropdown>}
    visible={isDropdownOpen}
    onVisibleChange={handleVisibleDropdown}
    trigger={['click']}
  >
    <DropdownFilterButton data-testid="table-filter-button" isOpen={isDropdownOpen} isFiltersPicked={isFiltersPicked}>
      <IconElement fill="var(--color-text-1)" name="headerFilter" />
      {isFiltersPicked ? ' ' : t('Фильтр')}
    </DropdownFilterButton>
  </Dropdown>
)

export const DateMenuItem: FC<{
  valueFrom?: string | null
  valueTo?: string | null
  open: boolean
  handleDataRangeChange: (
    dates: [Date | null, Date | null] | null,
    dateStrings: [string, string],
    filterType?: EUploadedFileFilterType,
  ) => void
}> = ({ handleDataRangeChange, open, valueFrom, valueTo }) => {
  const { i18n } = useTranslation()
  const dateLocale = getDatePickerLocale(i18n.language)

  return (
    <Menu.Item>
      <div onClick={(e) => e.stopPropagation()}>
        <DateRangePicker
          style={{ left: -480, top: -25 }}
          locale={dateLocale}
          open={open}
          onChange={handleDataRangeChange}
          value={[valueFrom ? parseISO(valueFrom) : null, valueTo ? parseISO(valueTo) : null]}
          getPopupContainer={(trigger) => {
            if (trigger?.parentNode && trigger?.parentNode instanceof HTMLElement) {
              return trigger?.parentNode
            }
            return document.body
          }}
        />
      </div>
    </Menu.Item>
  )
}

export const ContainerSubmenu = styled.div`
  align-items: center;
  display: flex;
  width: 216px;
`

export const LabelContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserAvatar = styled(AvatarElement)`
  vertical-align: middle;
  margin: 0 8px;
  flex-shrink: 0;
`

const DropdownWrapper = styled.div<{ isFiltersPicked: boolean }>`
  background-color: var(--color-bg-1);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${(props) => (props.isFiltersPicked ? '8px' : '0')};
`

export const StyledDropdown = styled(Menu)`
  position: relative;

  width: 236px;
  opacity: 1;

  ${() => modalBackground}
  ${() => modalShadow}

    .ant-dropdown-menu-item {
    align-items: center;
    align-content: center;
  }
  .ant-dropdown-menu-title-content {
    display: flex;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 5px;
  }
  .sub-menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ant-dropdown-menu-submenu-open {
    background-color: var(--color-bg-3) !important;
  }
`
